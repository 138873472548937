import { getType } from 'typesafe-actions';
import { cloManagersActions, TCloManagersActions } from '../actions/clo-managers.actions';
import { CloManager } from '../types/clo-managers/CloManager';
import { CloManagersState } from '../types/state/CloManagersState';
import { compareBoolean, compareNumbersWithNullAcs, compareStrings } from '../utils/compare.utils';
import { analyticDefaultFilters } from '../constants';
import { dateRangeFilterOptions } from '../constants/date-range.filter';

const cloManagersInitialState: CloManagersState = {
    isLoading: true,
    isDetailsLoading: true,
    isUpdating: false,
    analytics: {
        isLoading: true,
        users: [],
        clientsActivity: {
            isLoading: false,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
        profileHistoryViews: {
            isLoading: true,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
    },
    selectedSecurity: undefined,
};

function sortCloManagers(data: CloManager[]) {
    return data.sort(
        (a, b) =>
        compareBoolean(a.isUserCompany, b.isUserCompany) ||
        compareStrings(a.legalName, b.legalName)
    );
}

function markFavorite(referenceName: string, favorite: boolean, data?: CloManager[]) {
    if (!data) {
        return [];
    }

    return data.map(cloManager => ({
        ...cloManager,
        favorite: cloManager.referenceName === referenceName
            ? favorite
            : cloManager.favorite,
    }));
}

export const cloManagers = (state = cloManagersInitialState, action: TCloManagersActions): CloManagersState => {
    switch (action.type) {
        case getType(cloManagersActions.getCloManagers):
            return {
                ...state,
                isLoading: true,
            };
        case getType(cloManagersActions.getCloManagersResult): {
            return {
                ...state,
                isLoading: false,
                data: sortCloManagers(action.payload.cloManagers),
            };
        }
        case getType(cloManagersActions.getCloManager):
            return {
                ...state,
                selectedManager: state.data?.find(x => x.referenceName === action.payload.referenceName),
                isDetailsLoading: true,
            };
        case getType(cloManagersActions.setManagerForAlertPopup):
            return {
                ...state,
                managerReferenceNameForAlert: action.payload.referenceName,
            };
        case getType(cloManagersActions.getCloManagerResult): {
            const {
                cloManager,
                referenceName,
                bwicCloManagers,
            } = action.payload;

            const investmentTeam = {
                ...cloManager.investmentTeam,
                items: cloManager.investmentTeam.items
                    ?.filter((contact) => !bwicCloManagers.some(m => m.fullName === contact.fullName))
                    .sort((a, b) => a.order! - b.order!)
            };

            const sortedBwicCloManagers = bwicCloManagers.sort(
                (a , b) => compareNumbersWithNullAcs(a.status, b.status)
                || compareStrings(a.fullName, b.fullName)
            );

            return {
                ...state,
                isDetailsLoading: false,
                isUpdating: false,
                selectedManager:
                    state.selectedManager?.referenceName === referenceName
                        ? { ...state.selectedManager, details: { ...cloManager, investmentTeam } }
                        : state.selectedManager,
                editManager: {
                    ...cloManager,
                    investmentTeam: investmentTeam.items,
                    ownEquity: cloManager.ownEquity || false,
                    proprietaryAnalyticalTools: cloManager.proprietaryAnalyticalTools || false,
                    countryId: cloManager.country ? cloManager.country.id : null,
                    stateId: cloManager.state ? cloManager.state.id : null,
                    cityId: cloManager.city ? cloManager.city.id : null,
                    newCity: null,
                },
                bwicCloManagers: sortedBwicCloManagers,
                editBwicCloManagers: sortedBwicCloManagers,
            };
        }
        case getType(cloManagersActions.editPlatformUsers): {
            const { email } = action.payload;

            const data = state.editBwicCloManagers?.map((manager) => {
                if (manager.email === email) {
                    return {
                        ...manager,
                        meetingContact: !manager.meetingContact,
                    };
                }

                return manager;
            });

            return {
                ...state,
                editBwicCloManagers: data,
            };
        }
        case getType(cloManagersActions.resetEditPlatformUsers): {
            return {
                ...state,
                editBwicCloManagers: state.bwicCloManagers
            }
        }
        case getType(cloManagersActions.setCloManagerSaveState):
            return {
                ...state,
                isUpdating: action.payload.isUpdating,
            };
        case getType(cloManagersActions.switchFavoriteResponse): {
            const { companyReferenceName, favorite } = action.payload;

            const data = markFavorite(companyReferenceName, favorite, state.data);

            return {
                ...state,
                data,
            };
        }
        case getType(cloManagersActions.analyticsInitResponse):
            const { users, analytics, numberOfHiddenItems } = action.payload;
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    users,
                    general: analytics,
                    isLoading: false,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        case getType(cloManagersActions.analyticsReset):
            return {
                ...state,
                analytics: cloManagersInitialState.analytics,
            };
        case getType(cloManagersActions.clientsActivityRequest):
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        isLoading: true,
                    },
                },
            };
        case getType(cloManagersActions.clientsActivityResponse): {
            const { data, numberOfHiddenItems } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        data,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(cloManagersActions.profileViewHistoryResponse): {
            const { data, numberOfHiddenItems } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        data,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(cloManagersActions.clientsActivityFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        filter: {
                            ...state.analytics.clientsActivity.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(cloManagersActions.profileViewHistoryFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        filter: {
                            ...state.analytics.profileHistoryViews.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(cloManagersActions.profileViewHistoryFilterByBar): {
            const { accessType, date } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        filter: {
                            ...cloManagersInitialState.analytics.profileHistoryViews.filter,
                            tabs: [accessType],
                            dateOption: dateRangeFilterOptions.Custom,
                            date: {
                                dateFrom: date,
                                dateTo: date,
                            }
                        },
                    },
                },
            };
        }
        case getType(cloManagersActions.selectedSecurityChange):
            return {
                ...state,
                selectedSecurity: action.payload.security
            }
        case getType(cloManagersActions.reset):
            return cloManagersInitialState;
        default:
            return state;
    }
}
